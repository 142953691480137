<template>
    <a-input :placeholder="placeholder" v-model="inputValue">
        <a-date-picker
            slot="addonAfter"
            v-model="inputDate"
            :valueFormat="valueFormat"
            @change="onChange"
            @openChange="openChange"
        >
            <a-icon type="calendar" style="cursor: pointer" />
        </a-date-picker>
    </a-input>
</template>

<script>
export default {
    props: {
        placeholder: {
            type: String,
            default: '请输入日期',
        },
        value: {
            type: String | Number | Object,
            default: '',
        },
        valueFormat: {
            type: String,
            default: 'YYYY-MM-DD',
        },
    },
    data() {
        return {
            inputValue: '',
            inputDate: '',
        };
    },
    watch: {
        inputValue(nVal) {
            // 双向绑定值，让v-model绑定的值双向变化
            this.$emit('input', nVal);
        },
        value: {
            immediate: true,
            handler(nVal) {
                console.log('value', nVal);
                this.inputValue = nVal;
            },
        },
    },
    mounted() {
        this.inputValue = this.value;
    },
    methods: {
        onChange(e) {
            this.inputValue = e;
            this.$emit('pickerChange', this.inputValue);
        },
        openChange(e) {
            if (e) {
                if (this.inputValue) {
                    this.inputDate = this.inputValue;
                }
            }
        },
    },
};
</script>

<style scoped></style>
