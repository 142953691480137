<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <div class="modal-header">
                <div class="modal-title">
                    <span>{{ l('导入固定项变动') }}</span>
                </div>
            </div>
            <div class="my-md">
                <a-row class="text">
                    1.其中<b>姓名</b>和<b>工号</b>是必须的
                </a-row>
                <a-row class="text">
                    2.<b>更改值</b>请填写数值
                </a-row>
<!--                <a-row class="text">-->
<!--                    3.<b>变更项目、变更方式、变更原因</b>请填写相对应编码值-->
<!--                </a-row>-->
                <a-row class="text">
                    3.导入文件必须是xls,xlsx格式
                </a-row>
                <a-row class="text">
                    <a-col :span="5">
                        <a-button
                                @click="ExportTemplate()"
                        >
                            <a-icon type="file-excel"/>
                            <span>{{ l("下载模板") }}</span>
                        </a-button>
                    </a-col>
                    <a-col :span="19">
                        <a-upload
                                :action="uploadPictureUrl"
                                :beforeUpload="beforeUpload"
                                :headers="uploadHeaders"
                                @change="uploadPictureChange($event)">
                            <a-button type="primary">
                                <a-icon type="import"/>
                                导入
                            </a-button>
                        </a-upload>
                    </a-col>
                </a-row>
            </div>
        </a-spin>
    </a-config-provider>
</template>

<script>
    import ModalComponentBase from "../../../../shared/component-base/modal-component-base";
    import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
    import {fileDownloadService} from "../../../../shared/utils";
    import {AppConsts} from "../../../../abpPro/AppConsts";
    import {ChangeRecordServiceProxy} from "../../../../shared/service-proxies";

    export default {
        name: "import-excel-salary-change",
        mixins: [ModalComponentBase],
        data(){
            return{
                zh_CN,
                uploadPictureUrl: AppConsts.remoteServiceBaseUrl + "/Profile/UploadExcelFile",
                uploadHeaders: {
                    Authorization: "Bearer " + abp.auth.getToken()
                },
            }
        },
        created() {
            this.fullData();
            this._fileDownloadService = fileDownloadService;
            this._changeRecordServiceProxy=new ChangeRecordServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {

        },
        methods:{
            beforeUpload(file) {
                let filetype;
                if (file.name) {
                    filetype = file.name.substring(file.name.lastIndexOf('.'));
                }
                let isExcel = filetype === ".xlsx" ||
                    filetype === ".xls";
                if (!isExcel) {
                    return abp.message.warn("只能上传xlsx格式和xls的文件");
                }

            },
            uploadPictureChange({file, fileList}) {
                if (file.status === 'done') {
                    let filename=file.response.result.fileName;
                    this.loading=true;
                    this._changeRecordServiceProxy.importSalaryChange(filename)
                    .finally(()=>{this.loading=false})
                    .then(res=>{
                        if(!res.isSuccess&&res.failedCount>0){
                            this.$notification["warn"]({
                                message: "总条数:"+res.totalCount+"  失败行数:"+res.failedCount,
                            });
                            this._fileDownloadService.downloadTempFile(res.file);
                        }else {
                            this.$notification["success"]({
                                message: this.l("导入成功"),
                            });
                        }
                    })
                }
            },
            ExportTemplate() {
                this._changeRecordServiceProxy.exportTemplate()
                .then(res=>{
                    this._fileDownloadService.downloadTempFile(res);
                })
            },
        }
    }
</script>

<style scoped>
    .text {
        padding: 5px 0px;
    }
</style>
