<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<div>
				<a-row :gutter="10">
					<a-col :span="4">
						<a-tree-select
							class="left"
							@change="handleChange"
							:placeholder="l('Company')"
							style="width: 100%"
							allowClear
							:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
							:tree-data="companyTreeList"
							tree-default-expand-all
							v-model="companyId"
						/>
					</a-col>
					<a-col :span="4">
						<a-tree-select
							v-model="depId"
							allowClear
							style="width: 100%"
							show-search
							:filterTreeNode="searchTree"
							:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
							:tree-data="DepTreeData"
							:placeholder="l('Department')"
							tree-default-expand-all
							@change="search"
						>
						</a-tree-select>
					</a-col>
					<a-col :span="16" style="text-align: right">
						<a-input-search
							style="margin-top: 4px;max-width: 200px;"
							name="filterText"
							:placeholder="l('SearchWithThreeDot')"
							@search="search"
							enterButton
							v-model="filterText"
						/>
					</a-col>
				</a-row>
			</div>
			<!--                        条件、按钮-->
			<div class="my-md">
				<a-row>
					<a-col :span="24">
						<a-button type="primary" @click="batchSalaryChange" v-if="isGranted('sl_salary_change_edit')">
							<a-icon type="edit" />
							批量变动
						</a-button>
						<a-button type="primary" @click="importExcel" v-if="isGranted('sl_salary_change_excel')">
							<a-icon type="import" />
							<span>批量导入变动</span>
						</a-button>
						<a-button type="primary" @click="detailview" v-if="isGranted('sl_salary_change_view')">
							<a-icon type="idcard" />
							<span>查看变动记录</span>
						</a-button>
					</a-col>
				</a-row>
			</div>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				@change="sorterChange"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ x: 1500, y: scopeParam.scroll.y }"
				:dataSource="tableData"
				:customRow="customRow"
				:rowClassName="rowClassName"
			>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '../../../shared/component-base';
import {
	BaseInfoServiceProxy,
	ChangeRecordServiceProxy,
	CompanyServiceProxy,
	DepartmentServiceProxy,
	FixedInfoServiceProxy,
	OrganizationUnitServiceProxy,
} from '../../../shared/service-proxies';
import * as _ from 'lodash';
import moment from 'moment';
import modalHelper from '../../../shared/helpers/modal/modal-helper';
import ImportExcelSalaryChange from './import-excel-salary-change/import-excel-salary-change';
import SalaryChangeDetailView from './salary-change-detail-view/salary-change-detail-view';
import DepTreeUntils from '../../organization/department/dep-tree-untils/dep-tree-untils';
import ChangeItemModel from './change-item-model/change-item-model';

export default {
	name: 'salary-change',
	mixins: [AppComponentBase],
	components: { ImportExcelSalaryChange, SalaryChangeDetailView, ChangeItemModel },
	data() {
		return {
			companyId: undefined,
			companyList: [],
			SelectKey: [],
			expandedKeys: [],
			treeData: [],
			DepTreeData: [],
			companyTreeList: [],
			depId: undefined,
			_ouData: {},
			// 用户表格
			columns: [
				{
					title: '工号',
					dataIndex: 'jobNumber',
					sorter: true,
					ellipsis: true,
					fixed: 'left',
					width: 100,
					align: 'center',
					scopedSlots: { customRender: 'jobNumber' },
				},
				{
					title: '姓名',
					dataIndex: 'realName',
					sorter: true,
					fixed: 'left',
					width: 100,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'realName' },
				},
				{
					title: '公司',
					dataIndex: 'companyName',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'companyName' },
				},
				{
					title: '部门',
					dataIndex: 'department',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'department' },
				},
				{
					title: '岗位',
					dataIndex: 'post',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'post' },
				},
				{
					title: '岗位等级',
					dataIndex: 'postGrade',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'postGrade' },
				},
				{
					title: '用工形式',
					dataIndex: 'empKindOne',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'empKindOne' },
				},
				{
					title: '入职日期',
					dataIndex: 'hireDate',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'hireDate' },
				},
				{
					title: this.l('离职日期'),
					dataIndex: 'termDate',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'termDate' },
				},
			],
			tableData: [],

			items: [],
		};
	},
	created() {
		this._organizationUnitServiceProxy = new OrganizationUnitServiceProxy(this.$apiUrl, this.$api);
		this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._baseInfoServiceProxy = new BaseInfoServiceProxy(this.$apiUrl, this.$api);
		this._changeRecordServiceProxy = new ChangeRecordServiceProxy(this.$apiUrl, this.$api);
		this._fixedInfoServiceProxy = new FixedInfoServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		this.companyTreeInit();
		this.getData();
	},
	methods: {
		getData() {
			this.loading = true;
			this._fixedInfoServiceProxy
				.getPaged(
					undefined,
					undefined,
					this.companyId,
					this.depId,
					undefined,
					undefined,
					undefined,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.tableData.map((item) => {
						item.hireDate = item.hireDate ? moment(item.hireDate).format('YYYY-MM-DD') : '';
						item.termDate = item.termDate ? moment(item.termDate).format('YYYY-MM-DD') : '';
						item.isPay = item.isPay ? '是' : '否';
						item.isHaveSocial = item.isHaveSocial ? '有' : '无';
					});
					this.totalItems = res.totalCount;
				});
		},
		companyTreeInit() {
			this._CompanyConfigServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.companyList = res.items;
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		searchTree(inputValue, treeNode) {
			return treeNode.data.props.title.includes(inputValue);
		},
		handleChange(value) {
			this.companyId = value;
			this.refresh();
			this.departmentId = undefined;
			this.getDepTreeData();
		},
		/**
		 * 获取部门
		 */
		getDepTreeData() {
			this.DepTreeData = [];
			this._DepartmentServiceProxy
				.getDepTreeData(this.comPanyId)
				.then((res) => {
					this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
					this.DepTree.forEach((item) => {
						if (!item.parentId) {
							this.DepTreeData.push(item);
						}
					});
					this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
				})
				.finally(() => {
					this.loading = false;
				});
		},

		/**
		 * 展开
		 */
		onExpand(expandedKeys, record) {
			this.expandedKeys = expandedKeys;
		},

		importExcel() {
			modalHelper.create(ImportExcelSalaryChange, {}, { width: '800px' }).subscribe((res) => {});
		},
		detailview() {
			if (this.selectedRows.length <= 0) {
				return abp.message.warn('请至少选择一项进行操作');
			}
			if (this.selectedRows.length > 1) {
				return abp.message.warn('只能选择一项进行操作');
			}
			modalHelper
				.create(
					SalaryChangeDetailView,
					{
						empId: this.selectedRows[0].empId,
						realName: this.selectedRows[0].realName,
						jobNumber: this.selectedRows[0].jobNumber,
					},
					{ width: '1000px' }
				)
				.subscribe((res) => {});
		},
		batchSalaryChange() {
			if (this.selectedRows.length <= 0) {
				return abp.message.warn('请至少选择一项进行操作');
			}
			modalHelper
				.create(
					ChangeItemModel,
					{
						dataList: this.selectedRows,
						items: this.items,
					},
					{ width: '600px' }
				)
				.subscribe((res) => {
					if (res) {
						this.items = res;
						this.search();
					}
				});
		},
	},
};
</script>

<style scoped></style>
