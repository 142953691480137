<template>
	<div>
		<div class="modal-header">
			<div class="modal-title">
				<span>{{ l('批量变动') }}</span>
			</div>
		</div>
		<!-- usePagination开启分页 -->
		<!-- loading绑定引入页面的蒙层加载变量 -->
		<q-container useModal :loading="loading">
			<!-- 使用具名插槽 #toolbar -->
			<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
			<template #toolbar>
				<a-row>
					<a-col :span="2" style="text-align: right;padding-right: 5px">
						生效日期:
					</a-col>
					<a-col :span="4">
						<a-date-picker v-model="effectiveDate" @change="effectiveDateChange" />
					</a-col>
					<a-col :span="2" style="text-align: right;padding-right: 5px">
						变更方式:
					</a-col>
					<a-col :span="4">
						<a-select
							show-search
							:placeholder="l('变更方式')"
							option-filter-prop="children"
							style="width: 90%"
							v-model="changeWay"
							@change="changeWayChangeall"
						>
							<a-select-option v-for="item in ChangeWayList" :key="item.itemDetailCode">
								{{ item.itemDetailName }}
							</a-select-option>
						</a-select>
					</a-col>
				</a-row>
			</template>
			<!-- 使用具名插槽slot="table" -->
			<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
			<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

			<!-- **************行选择*************** -->
			<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
			<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
			<template slot="table" slot-scope="scopeParam">
				<a-table
					ref="table"
					:pagination="false"
					:columns="columns"
					:rowKey="(tableDatas) => tableDatas.id"
					:scroll="{ x: 1500, y: scopeParam.scroll.y }"
					:dataSource="tableData"
				>
					<span slot="index" slot-scope="text, record, index">
						{{ index + 1 }}
					</span>
					<span slot="effectiveDate" slot-scope="text, record">
						<q-date-picker v-model="record.effectiveDate" />
					</span>
					<span slot="changeWay" slot-scope="text, record">
						<a-select
							show-search
							allowClear
							:placeholder="l('变更方式')"
							option-filter-prop="children"
							style="width: 100%"
							v-model="record.changeWay"
							@change="changewayChange(record)"
						>
							<a-select-option v-for="item in ChangeWayList" :key="item.itemDetailCode">
								{{ item.itemDetailName }}
							</a-select-option>
						</a-select>
					</span>
					<span slot="changeValue" slot-scope="text, record">
						<a-input v-model="record.changeValue" style="width: 100%" @change="calcNewSalary(record)" />
					</span>
					<span slot="newAmount" slot-scope="text, record">
						<a-input v-model="record.newAmount" style="width: 100%" />
					</span>
					<span slot="changeReason" slot-scope="text, record">
						<a-select
							show-search
							allowClear
							:placeholder="l('变更原因')"
							option-filter-prop="children"
							style="width: 100%"
							v-model="record.changeReason"
						>
							<a-select-option v-for="item in ChangeReasonList" :key="item.itemDetailCode">
								{{ item.itemDetailName }}
							</a-select-option>
						</a-select>
					</span>
					<span slot="action" slot-scope="text, record, index">
						<a-button type="link" @click="removeItem(index)">
							移除
						</a-button>
					</span>
				</a-table>
			</template>
		</q-container>
		<!-- 按钮 -->
		<div class="modal-footer">
			<a-button @click="close" type="button">
				<a-icon type="close-circle" />
				{{ l('Cancel') }}
			</a-button>
			<a-button :type="'primary'" @click="save">
				<a-icon type="save" />
				保存
			</a-button>
		</div>
	</div>
</template>

<script>
import ModalComponentBase from '@/shared/component-base/modal-component-base';
import {
	ChangeRecordServiceProxy,
	CompanyServiceProxy,
	DepartmentServiceProxy,
	FixedInfoServiceProxy,
	GetBatchEditListDto,
} from '@/shared/service-proxies';
import { Dic } from '@/shared/utils';
import DepTreeUntils from '../../../organization/department/dep-tree-untils/dep-tree-untils';
import moment from 'moment';
import qDatePicker from '@/components/tools/q-date-picker.vue';
import { cloneDeepWith } from 'lodash';

export default {
	name: 'batch-salary-change',
	mixins: [ModalComponentBase],
	components: { qDatePicker },
	data() {
		return {
			companyTreeList: [],
			companyId: undefined,
			departmentId: undefined,
			//部门树
			DepTreeData: [],
			DepTree: [],

			startTime: undefined,
			endTime: undefined,
			items: [],
			dataList: [],
			columns: [
				{
					title: '',
					dataIndex: 'index',
					sorter: false,
					ellipsis: true,
					align: 'center',
					width: 30,
					fixed: 'left',
					scopedSlots: { customRender: 'index' },
				},
				{
					title: '工号',
					dataIndex: 'jobNumber',
					sorter: false,
					ellipsis: true,
					align: 'center',
					width: 100,
					fixed: 'left',
					scopedSlots: { customRender: 'jobNumber' },
				},
				{
					title: '姓名',
					dataIndex: 'realName',
					sorter: false,
					ellipsis: true,
					align: 'center',
					fixed: 'left',
					width: 100,
					scopedSlots: { customRender: 'realName' },
				},
				{
					title: '公司',
					dataIndex: 'companyName',
					sorter: false,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'companyName' },
				},
				{
					title: '部门',
					dataIndex: 'department',
					sorter: false,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'department' },
				},
				{
					title: '变更项目',
					dataIndex: 'changeItemName',
					sorter: false,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'changeItemName' },
				},
				{
					title: '生效日期',
					dataIndex: 'effectiveDate',
					sorter: false,
					ellipsis: true,
					align: 'center',
					width: 170,
					scopedSlots: { customRender: 'effectiveDate' },
				},
				{
					title: '变更方式',
					dataIndex: 'changeWay',
					sorter: false,
					ellipsis: true,
					align: 'center',
					width: 150,
					scopedSlots: { customRender: 'changeWay' },
				},
				{
					title: '更改值',
					dataIndex: 'changeValue',
					sorter: false,
					ellipsis: true,
					width: 100,
					align: 'center',
					scopedSlots: { customRender: 'changeValue' },
				},
				{
					title: '原金额',
					dataIndex: 'originalAmount',
					sorter: false,
					width: 100,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'originalAmount' },
				},
				{
					title: '新金额',
					dataIndex: 'newAmount',
					sorter: false,
					width: 100,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'newAmount' },
				},
				{
					title: '变更原因',
					dataIndex: 'changeReason',
					sorter: false,
					ellipsis: true,
					align: 'center',
					width: 180,
					scopedSlots: { customRender: 'changeReason' },
				},
				{
					title: '',
					dataIndex: 'action',
					align: 'center',
					width: 80,
					fixed: 'right',
					scopedSlots: { customRender: 'action' },
				},
			],
			tableData: [],
			itemsOptions: [],
			ChangeWayList: [],
			ChangeReasonList: [],
			datasource: [],
			effectiveDate: undefined,
			changeWay: undefined,
		};
	},
	created() {
		this.fullData();
		this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._changeRecordServiceProxy = new ChangeRecordServiceProxy(this.$apiUrl, this.$api);
		this._fixedInfoServiceProxy = new FixedInfoServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		try {
			this.loading = true;
			this.itemsOptions = await this.getItems();
			this.ChangeWayList = await Dic.getInstance().getDicAsync('QYB_SL_ChangeWay');
			this.ChangeReasonList = await Dic.getInstance().getDicAsync('QYB_SL_ChangeReason');
			this.changeWay = 'fixedSalary';
		} catch (e) {
			console.error(e);
		} finally {
			this.loading = false;
		}

		// this.companyTreeInit();
		this.getData();
		this.changeWayChangeall(this.changeWay);
	},
	methods: {
		/**
		 * 获取变更项目
		 */
		getItems() {
			return new Promise((resolve) => {
				this.loading = true;
				this._changeRecordServiceProxy
					.getSalaryChangeItemsData()
					.finally(() => {
						this.loading = false;
					})
					.then((res) => {
						resolve(res);
					});
			});
		},
		/**
		 * 刷新
		 */
		refresh() {
			this.tableData = this.datasource;
			if (this.comPanyId) {
				this.tableData = this.tableData.filter((item) => item.companyId == this.comPanyId);
			}
			if (this.departmentId) {
				this.tableData = this.tableData.filter((item) => item.departmentId == this.departmentId);
			}
			if (this.startTime && this.endTime) {
				this.tableData = this.tableData.filter(
					(item) => moment(item.effectiveDate) >= this.startTime && moment(item.effectiveDate) <= this.endTime
				);
			}
			if (this.filterText) {
				this.tableData = this.tableData.filter(
					(item) =>
						item.jobNumber.indexOf(this.filterText) != -1 || item.realName.indexOf(this.filterText) != -1
				);
			}
		},
		/**
		 * 获取数据
		 */
		getData() {
			this.loading = true;
			this.tableData = [];
			let empIds = _.map(this.dataList, 'empId');
			this._fixedInfoServiceProxy
				.getEmpFixedInfo(empIds)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					res.forEach((data) => {
						this.items.forEach((item) => {
							let e = this.itemsOptions.find((t) => t.code == item);
							let c = new GetBatchEditListDto();
							c.empId = data.empId;
							c.jobNumber = data.jobNumber;
							c.realName = data.realName;
							c.companyName = data.companyName;
							c.department = data.department;
							c.changeItem = e ? e.code : '';
							c.changeItemName = e ? e.name : '';
							c.changeValue = 0;
							c.newAmount = 0;
							c.companyId = data.companyId;
							c.departmentId = data.departmentId;
							switch (item) {
								case 'MonthlyPay':
									c.originalAmount = data.monthlyPay ? data.monthlyPay : 0;
									break;
								case 'BasePay':
									c.originalAmount = data.basePay ? data.basePay : 0;
									break;
								case 'ManagerAdd':
									c.originalAmount = data.managerAdd ? data.managerAdd : 0;
									break;
								case 'PostAllowance':
									c.originalAmount = data.psotAllowance ? data.psotAllowance : 0;
									break;
								case 'SkillAllowance':
									c.originalAmount = data.skillAllowance ? data.skillAllowance : 0;
									break;
								case 'FullAllBonus':
									c.originalAmount = data.fullAllBonus ? data.fullAllBonus : 0;
									break;
								case 'CommunicationSubsidy':
									c.originalAmount = data.communicationSubsidy ? data.communicationSubsidy : 0;
									break;
								case 'GuaranteedPay':
									c.originalAmount = data.guaranteedPay ? data.guaranteedPay : 0;
									break;
								case 'PrepaidOtPay':
									c.originalAmount = data.prepaidOtPay ? data.prepaidOtPay : 0;
									break;
							}
							this.tableData = [...this.tableData, c];
						});
						this.datasource = this.tableData;
					});
				});
		},
		effectiveDateChange(v) {
			this.tableData.map((item) => {
				item.effectiveDate = v ? moment(v).format('yyyy-MM-DD') : undefined;
			});
			this.$forceUpdate();
		},
		changeWayChangeall(v) {
			this.tableData.map((item) => {
				item.changeWay = v ? v : undefined;
			});
			this.$forceUpdate();
		},
		searchTree(inputValue, treeNode) {
			return treeNode.data.props.title.includes(inputValue);
		},
		companyTreeInit() {
			this.loading = true;
			this._CompanyConfigServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.companyList = res.items;
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		handleChange(value) {
			this.companyId = value;
			this.refresh();
			this.departmentId = undefined;
			this.getDepTreeData();
		},
		/**
		 * 获取部门
		 */
		getDepTreeData() {
			this.DepTreeData = [];
			this._DepartmentServiceProxy
				.getDepTreeData(this.companyId)
				.then((res) => {
					this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
					this.DepTree.forEach((item) => {
						if (!item.parentId) {
							this.DepTreeData.push(item);
						}
					});
					this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		/**
		 * 选择部门
		 */
		SelectDepartmentTree(e) {
			this.departmentId = e;
		},
		PickerChange(date) {
			if (date.length > 0) {
				this.startTime = moment(date[0].format('YYYY-MM-DD'));
				this.endTime = moment(date[1].format('YYYY-MM-DD'));
			} else {
				this.startTime = undefined;
				this.endTime = undefined;
			}
			this.refresh();
		},

		/**
		 * 变更方式change事件
		 */
		changewayChange(record) {
			this.calcNewSalary(record);
			if (!record.changeWay) {
				record.newAmount = 0;
				record.changeValue = 0;
			}
			this.$forceUpdate();
		},
		/**
		 * 计算新金额
		 * @param record
		 */
		calcNewSalary(record) {
			switch (record.changeWay) {
				//按比例
				case 'ratio':
					record.newAmount = record.originalAmount + record.originalAmount * (record.changeValue / 100);
					break;
				//按金额变动
				case 'money':
					record.newAmount = record.originalAmount + record.changeValue;
					break;
				//按定薪金额
				case 'fixedSalary':
					record.newAmount = record.changeValue;
					break;
			}
		},
		save() {
			let checkErr = false;
			let err = '';
			for (let i = 0; i < this.datasource.length; i++) {
				if (!this.datasource[i].effectiveDate) {
					checkErr = true;
					err = '第 ' + (i + 1) + ' 行的生效日期不能为空';
					break;
				}
				if (!this.datasource[i].changeWay) {
					checkErr = true;
					err = '第 ' + (i + 1) + ' 行的变更方式不能为空';
					break;
				}
				if (this.datasource[i].changeValue != 0 && !this.datasource[i].changeValue) {
					checkErr = true;
					err = '第 ' + (i + 1) + ' 行的变更值不能为空';
					break;
				}
				if (this.datasource[i].newAmount != 0 && !this.datasource[i].newAmount) {
					checkErr = true;
					err = '第 ' + (i + 1) + ' 行的新金额不能为空';
					break;
				}
				if (!this.datasource[i].changeReason) {
					checkErr = true;
					err = '第 ' + (i + 1) + ' 行的变更原因不能为空';
					break;
				}
			}
			if (checkErr) {
				return abp.message.warn(err);
			}
			this.loading = true;
			this._changeRecordServiceProxy
				.batchSalaryChange(
					cloneDeepWith(this.datasource).map((data) => {
						data.effectiveDate = moment(data.effectiveDate);
						return data;
					})
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
		//移除表格中已存在的薪资变动项
		removeItem(index) {
			this.tableData.splice(index, 1);
			this.$forceUpdate();
		},
	},
};
</script>

<style scoped></style>
