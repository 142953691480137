<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="loading">
			<div class="modal-header">
				<div class="modal-title">
					<span>选择变更项目</span>
				</div>
			</div>
			<div>
				<a-checkbox-group @change="onChange" v-model="items">
					<a-row :gutter="[10, 20]">
						<a-col :span="8" v-for="item in itemsOptions" :key="item.value">
							<a-checkbox :value="item.value">
								{{ item.label }}
							</a-checkbox>
						</a-col>
					</a-row>
				</a-checkbox-group>
			</div>
			<div>
				<!-- 按钮 -->
				<div class="modal-footer">
					<a-button @click="close" type="button">
						<a-icon type="close-circle" />
						{{ l('Cancel') }}
					</a-button>
					<a-button :type="'primary'" @click="save">
						<a-icon type="save" />
						确认
					</a-button>
				</div>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
import { ChangeRecordServiceProxy } from '../../../../shared/service-proxies';
import modalHelper from '../../../../shared/helpers/modal/modal-helper';
import BatchSalaryChange from '../batch-salary-change/batch-salary-change';

export default {
	name: 'change-item-model',
	mixins: [ModalComponentBase],
	data() {
		return {
			zh_CN,
			itemsOptions: [],
			items: [],
			dataList: [],
		};
	},
	created() {
		this.fullData();
		this._changeRecordServiceProxy = new ChangeRecordServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		let items = await this.getItems();
		this.itemsOptions = items.map((item) => {
			return { label: item.name, value: item.code, sort: item.sort };
		});
	},
	methods: {
		/**
		 * 获取变更项目
		 */
		getItems() {
			return new Promise((resolve) => {
				this.loading = true;
				this._changeRecordServiceProxy
					.getSalaryChangeItemsData()
					.finally(() => {
						this.loading = false;
					})
					.then((res) => {
						resolve(res);
					});
			});
		},
		onChange(v) {
			this.items = v;
		},
		save() {
			if (this.items.length <= 0) {
				return abp.message.warn('请至少选择一项变更项目进行操作');
			}
			let items = [];
			this.itemsOptions.forEach((item) => {
				if (this.items.filter((t) => t == item.value).length > 0) {
					items.push(item.value);
				}
			});
			modalHelper
				.create(
					BatchSalaryChange,
					{
						dataList: this.dataList,
						items: items,
					},
					{
						width: '1000px',
					}
				)
				.subscribe((res) => {
					if (res) {
						this.success(items);
					}
				});
		},
	},
};
</script>

<style scoped></style>
